<template>
  <div id="background" :style="styleObject"></div>
  <LanguageSelector v-model="language" />
  <div v-if="mode == 'loading'" class="center">
    <spinner />
  </div>
  <div v-if="mode == 'enter'" class="center">
    <img style="width: 180px" src="./assets/logo.png" />
    <br />
    <br />
    <b>{{ candidateInfo.candidateName }}</b>
    <br />
    <br />
    {{ locale.gdpr.start }}
    <a @click="mode = 'gdpr'">{{ locale.gdpr.main }}</a
    >:
    <input type="checkbox" v-model="gdpr" />
    <br />
    <p style="font-size: 10px">
      {{ locale.text }}
    </p>
    <br />
    <hydrogen-button :disabled="!gdpr" flavour="primary" @click="setGdpr">
      {{ locale.saveText }}
    </hydrogen-button>
  </div>
  <div v-if="mode == 'send'" class="center">
    <img style="width: 180px" src="./assets/logo.png" />
    <br />
    <br />
    {{ locale.thanks }}
  </div>
  <div v-if="mode == 'error'" class="center">
    <p class="error">{{ errorMessage }}</p>
    <snarkdown v-if="configGdprText ?? locale.mainText" class="error-gdpr" :value="configGdprText ?? locale.mainText" />
  </div>
  <div v-if="mode == 'accepted'" class="center">
    <img style="width: 180px" src="./assets/logo.png" />
    <br />
    <br />
    {{ locale.alreadyAccepted }}
  </div>
  <div v-if="mode == 'gdpr'">
    <snarkdown
      v-if="configGdprText ?? locale.mainText"
      style="margin-left: 10%; margin-right: 10%; margin-top: 100px; margin-bottom: 24px"
      :value="configGdprText ?? locale.mainText"
    />
    <hydrogen-button style="position: fixed; left: 24px; top: 24px" flavour="primary" @click="mode = 'enter'">
      {{ locale.back }}
    </hydrogen-button>
  </div>
</template>

<script>
import HydrogenButton from './components/HydrogenButton.vue'
import HydrogenSpinner from './components/HydrogenSpinner.vue'
import Snarkdown from './components/Snarkdown.vue'
import LanguageSelector from './components/LanguageSelector.vue'
import i18n from './i18n.js'

import { configuration } from './config/env'

export default {
  name: 'App',
  data() {
    return {
      gdpr: false,
      mode: 'loading',
      candidateInfo: undefined,
      errorMessage: '',
      language: undefined,
      i18n: i18n,
      config: undefined,
      apiBaseUrl: undefined
    }
  },
  components: {
    HydrogenButton,
    spinner: HydrogenSpinner,
    Snarkdown,
    LanguageSelector
  },
  mounted() {
    if (!this.candidateId) {
      this.mode = 'error'
      this.errorMessage = ''
    }

    this.config = configuration
    console.log('config set')

    this.apiBaseUrl = this.config.general.apiBaseUrl

    this.language = this.langProp || 'de'

    if (this.candidateId == '' || this.candidateId == undefined) {
      this.mode = 'error'
      this.errorMessage = ''
      return
    }

    fetch(`${this.apiBaseUrl}/v4/gdpr?candidateId=${this.candidateId}`)
      .then(async res => {
        if (res.status === 200) {
          this.candidateInfo = await res.json()
          this.gdpr = this.candidateInfo.gdpr

          this.mode = 'enter'

          if (this.gdpr == false) this.mode = 'enter'
          else this.mode = 'accepted'
        } else {
          this.mode = 'error'
          this.errorMessage = 'Invalid ID'
        }
      })
      .catch(() => {
        this.mode = 'error'
        this.errorMessage = 'Invalid ID'
      })
  },
  computed: {
    candidateId() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      return params.candidateId ?? params.id
    },
    langProp() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      if (Object.keys(this.i18n).includes(params.lang)) {
        return params.lang
      } else {
        return 'de'
      }
    },
    locale() {
      return this.i18n[this.language]
    },
    styleObject() {
      if (this.config === undefined) return {}

      return {
        'background-image': `url(/assets/themes/${this.config.general.theme}/background.svg)`
      }
    },
    configGdprText() {
      if (!this.language || !this.config || !this.config.gdprTextLocales || !this.config.gdprTextLocales[this.language])
        return undefined
      return this.config.gdprTextLocales[this.language]
    }
  },
  methods: {
    setGdpr() {
      var xhr = new XMLHttpRequest()

      xhr.open('PUT', `${this.apiBaseUrl}/v4/gdpr?candidateId=${this.candidateId}`, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(
        JSON.stringify({
          gdpr: this.gdpr
        })
      )

      this.mode = 'send'
    }
  },
  watch: {
    language() {
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search)
        searchParams.set('lang', this.language)
        window.history.replaceState(null, null, '?' + searchParams.toString())
      }
    }
  }
}
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
}

#app {
  position: absolute;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

a {
  color: #007490;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.3s;
  cursor: pointer;
}

a:hover,
a:focus {
  background-size: 100% 1px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: white;
  border-radius: 6px;
}

.error {
  color: red;
  font-weight: bold;
}

.error-gdpr {
  overflow-y: scroll;
  height: 400px;
  width: 100%;
}
</style>
